<template>
  <div class="col-12 md-col-5 login-box elevation-5">
    <p class="formTitle">Forgot your password?</p>
    <p class="formSubtitle">Fear not. We'll email you a password reset link.</p>
    <x-formgroup
      v-model="scheme.valid"
      class="pl-3 pr-3">
      <x-textfield
        v-bind="scheme.emailScheme"
        v-model="scheme.emailScheme.value"
      />
    </x-formgroup>

    <div class="text-xs-center btnGroup pl-4 pr-4">
      <x-button
        v-bind="buttonSubmit"
        class="btnSecondary" />
      <x-button
        v-bind="buttonReturn"
        class="btnSecondary text-capitalize" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      scheme: {
        emailScheme: {
          label: 'Email',
          value: '',
          appendIcon: 'email',
          required: true,
          rules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid'
          ]
        }
      },
      buttonSubmit: {
        content: 'Submit',
        borderRadius: 4,
        size: 'large',
        block: true,
        shadow: true
      },
      buttonReturn: {
        content: 'Return to sign in',
        borderRadius: 4,
        size: 'medium',
        prependIcon: 'keyboard_arrow_left',
        flat: true,
        click: () => {
          this.$router.push({
            path: 'loginnew'
          })
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

.login-box {
  border-radius: 10px;
  background: $white;

  .login-title {
    background-color: $gary;
    letter-spacing: 0.2rem;
  }

  .formTitle {
    background: -webkit-linear-gradient(#2C204B,#7D719B);
    background: -o-linear-gradient(#2C204B,#7D719B);
    background: -moz-linear-gradient(#2C204B,#7D719B);
    background: linear-gradient(#2C204B,#7D719B);

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 25px 6px;
    margin-bottom: 20px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .formSubtitle {
    color: $bluey-grey;
    padding: 0 21px;
  }

  .v-input{
    padding-left: 5px;
    padding-right: 5px;
  }
  .btnGroup{
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
}
</style>
