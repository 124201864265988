<template>
  <div class="col-12 md-col-5 login-box elevation-5">
    <p class="formTitle">Sign In</p>
    <x-formgroup
      v-model="scheme.valid"
      class="pl-3 pr-3">
      <v-layout
        row
        wrap>
        <v-flex md6>
          <x-select-filter
            v-bind="scheme.selectCompany"
            v-model="scheme.selectCompany.value"
          />
        </v-flex>
        <v-flex md6>
          <x-select-filter
            v-bind="scheme.selectLanguage"
            v-model="scheme.selectLanguage.value"
          />
        </v-flex>
      </v-layout>

      <x-textfield
        v-bind="scheme.emailScheme"
        v-model="scheme.emailScheme.value"
      />
      <x-textfield
        v-bind="scheme.passwordScheme"
        v-model="scheme.passwordScheme.value"
      />
    </x-formgroup>

    <div class="text-xs-center btnGroup pl-4 pr-4">
      <x-button
        v-bind="buttonSignin"
        class="btnPrimary" />
      <x-button
        v-bind="buttonForgotPassword"
        class="btnPrimary text-capitalize"
      />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      scheme: {
        selectCompany: {
          label: 'Company',
          placeholder: 'select company',
          items: [
            {
              text: 'Cashalo',
              value: '2'
            }
          ],
          value: ''
        },
        selectLanguage: {
          label: 'Language',
          placeholder: 'select language',
          items: [
            {
              text: 'English',
              value: 'English'
            }
          ],
          value: ''
        },
        emailScheme: {
          label: 'Email',
          value: '',
          appendIcon: 'email',
          required: true,
          rules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid'
          ]
        },
        passwordScheme: {
          label: 'Password',
          type: 'password',
          placeholder: 'password',
          appendIcon: 'lock',
          required: true,
          value: '',
          rules: []
        }
      },

      buttonSignin: {
        content: 'SIGN IN',
        borderRadius: 4,
        size: 'large',
        block: true,
        shadow: true
      },
      buttonForgotPassword: {
        content: 'Forgot Password?',
        borderRadius: 4,
        size: 'medium',
        flat: true,
        click: () => {
          this.$router.push({
            path: 'forgotpassword'
          })
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

.login-box {
  border-radius: 10px;
  background: $white;

  .login-title {
    background-color: $gary;
    letter-spacing: 0.2rem;
  }

  .formTitle {
    background: -webkit-linear-gradient(#2C204B,#7D719B);
    background: -o-linear-gradient(#2C204B,#7D719B);
    background: -moz-linear-gradient(#2C204B,#7D719B);
    background: linear-gradient(#2C204B,#7D719B);

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 25px 6px;
    margin-bottom: 20px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .v-input{
    padding-left: 5px;
    padding-right: 5px;
  }
  .btnGroup{
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
}
</style>
