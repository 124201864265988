<template>
  <div class="col-12 md-col-5 login-box elevation-5">
    <p class="formTitle">
      Reset password
    </p>

    <x-formgroup
      v-model="scheme.valid"
      class="pl-3 pr-3"
    >
      <x-textfield
        v-model="scheme.newPassword.value"
        v-bind="scheme.newPassword"
      />
      <x-textfield
        v-model="scheme.confirmPassword.value"
        v-bind="scheme.confirmPassword"
      />
    </x-formgroup>

    <div class="text-xs-center btnGroup pl-4 pr-4">
      <span @click="openResetSuccess">
        <x-button
          v-bind="buttonSubmit"
          class="btnSecondary"
        />
      </span>
      <x-dialog
        v-model="dialogResetSuccess.value"
        v-bind="dialogResetSuccess"
        @confirm="dialogConfirm"
      >
        <div
          slot="slot-btn"
          class="text-xs-center mt-3"
        >
          <x-button
            v-bind="buttonReturn"
            class="btnSecondary"
          />
        </div>
      </x-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogResetSuccess: {
        value: false,
        title: 'Reset Success!',
        content: 'Your password has been reset.',
        cancelText: null,
        confirmText: null,
        type: 'success'
      },

      scheme: {
        newPassword: {
          label: 'New Password',
          type: 'password',
          placeholder: 'enter new password',
          appendIcon: 'lock',
          required: true,
          value: '',
          rules: []
        },
        confirmPassword: {
          label: 'Confirm Password',
          type: 'password',
          placeholder: 'confirm new password',
          appendIcon: 'lock',
          required: true,
          value: '',
          rules: []
        }
      },
      buttonSubmit: {
        content: 'Submit',
        borderRadius: 4,
        size: 'large',
        block: true,
        shadow: true
      },
      buttonReturn: {
        content: 'Return to sign in',
        borderRadius: 4,
        size: 'medium',
        prependIcon: 'keyboard_arrow_left',
        outline: true,
        // flat: true,
        click: () => {
          this.$router.push({
            path: 'loginnew'
          })
        }
      }
    }
  },
  methods: {
    dialogConfirm (event) {
      console.log('confirmed with event:', event) // confirm button
    },
    openResetSuccess () {
      this.dialogResetSuccess.value = true // open Dialog
    }
  }
}
</script>

<style lang="stylus" scoped>

.login-box {
  border-radius: 10px;
  background: $white;

  .login-title {
    background-color: $gary;
    letter-spacing: 0.2rem;
  }

  .formTitle {
    background: -webkit-linear-gradient(#2C204B,#7D719B);
    background: -o-linear-gradient(#2C204B,#7D719B);
    background: -moz-linear-gradient(#2C204B,#7D719B);
    background: linear-gradient(#2C204B,#7D719B);

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 25px 6px;
    margin-bottom: 20px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .formSubtitle {
    color: $bluey-grey;
    padding: 0 21px;
  }

  .v-input{
    padding-left: 5px;
    padding-right: 5px;
  }
  .btnGroup{
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 35px;
  }
}
</style>
